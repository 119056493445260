import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ShoppingTitle from '../../components/shoppingTitle';
import ShoppingCheckPay from '../../components/shoppingCheckPay';
import ShoppingCheckPriceFreight from '../../components/shoppingCheckPriceFreight';
import ShoppingCheckPrice from '../../components/shoppingCheckPrice';

class Homepage extends Component {
  render() {
    // const price1 = 100;
    // const price2 = 100;
    // const sum = price1 + price2;
    return (
      <Layout showHeader={false} showHero={false}>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="product-content">
          <ShoppingTitle title="付款及配送方式" showActionBtn={false} />
          <ShoppingCheckPay />
        </div>
        <div className="divider"></div>
        <div className="product-content">
          <ShoppingCheckPriceFreight
            text="商品金額"
            price="4000"
            freight="運費"
            priceFreight="60"
          />
        </div>
        <hr />
        <div className="price-content">
          <ShoppingCheckPrice text="應付總額" price="4060" />
        </div>

        <style jsx>{`
          .product-content {
            padding: 30px 0;
            margin: 0px 16px;
            min-width: 343px;
          }

          .divider {
            height: 10px;
            background-color: #e0e0e0;
          }

          hr {
            border: none;
            height: 1px;
            background-color: #e0e0e0;
            min-width: 343px;
            margin: auto;
          }

          .price-content {
            padding: 15px 0 30px 0;
            margin: 0px 16px;
            min-width: 343px;
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
